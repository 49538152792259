"use client";

import { GetMembershipBenefitDocument } from "@/graphql/generated/graphql";
import { useQuery } from "@apollo/client";
import {
  Box,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import Image from "next/image";
import { useState } from "react";
import YouTube from "react-youtube";
import Markdown from "@/components/Markdown";
import { useConfig } from "@/app/ConfigProvider";
import Tile from "./Tile";

const Benefits: React.FC = () => {
  const { welcomeVideo: videoId } = useConfig();
  const { data: membershipBenefitsData } = useQuery(GetMembershipBenefitDocument);
  const [activeBenefitId, setActiveBenefitId] = useState<number | null>(null);

  const membershipBenefits = membershipBenefitsData?.membershipBenefits ?? [];

  return (
    <>
      <Box maxWidth={1200}>
        {videoId && (
          <Box overflow="hidden" borderRadius={10}>
            <YouTube videoId={videoId} />
          </Box>
        )}

        <SimpleGrid columns={[2, null, 3, 2, 3, 5]} spacing={6} mt={6} fontWeight="bold">
          {membershipBenefits.map(({ title, image, width }, i) => (
            <Tile
              key={title}
              onClick={() => setActiveBenefitId(i)}
              icon={
                <Box w={width ? `${width}%` : undefined}>
                  <Image
                    src={image}
                    alt=""
                    width={137}
                    height={171}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              }
            >
              {title}
            </Tile>
          ))}
        </SimpleGrid>
      </Box>

      <Modal isOpen={activeBenefitId !== null} onClose={() => setActiveBenefitId(null)}>
        <ModalOverlay />

        {activeBenefitId !== null && (
          <ModalContent m={8}>
            <ModalHeader>
              <Heading as="h2" size="md">
                {membershipBenefits[activeBenefitId].title}
              </Heading>
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <Markdown>{membershipBenefits[activeBenefitId].description}</Markdown>
            </ModalBody>
          </ModalContent>
        )}
      </Modal>
    </>
  );
};

export default Benefits;
